
import './Navbar2.css';

const Navbar2 = () => {
    return (
      <div className="navbar2">
        <div className="navbar-start">
        </div>
        <div className="navbar-center">
          <ul className="menu">
          </ul>
        </div>
      </div>
    );
  }
  
  export default Navbar2;