import React from "react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './Newoutcome.css';

const bootcamps = [
    { id: 1, name: "4Geeks Academy", location: "Miami, Florida" },
    { id: 2, name: "Actualize", location: "Chicago, Illinois" },
    { id: 3, name: "App Academy", location: "Remote" },
    { id: 4, name: "BrainStation", location: "Remote" },
    { id: 5, name: "CareerFoundry", location: "Remote" },
    { id: 6, name: "Claim Academy", location: "Remote" },
    { id: 7, name: "Code Fellows", location: "Remote" },
    { id: 8, name: "Code Platoon", location: "Chicago, IL" },
    { id: 9, name: "Coder Foundry", location: "Remote" },
    { id: 10, name: "Codesmith", location: "Los Angeles, California" },
    { id: 11, name: "Codeup", location: "Remote" },
    { id: 12, name: "Codeworks", location: "Barcelona, Spain" },
    { id: 13, name: "Coding Dojo", location: "Remote" },
    { id: 14, name: "Coding Temple", location: "Boston, Massachusetts" },
    { id: 15, name: "Concordia Bootcamps", location: "Remote" },
    { id: 16, name: "Covalence", location: "Remote" },
    { id: 17, name: "DevPoint Labs", location: "Remote" },
    { id: 18, name: "Devmountain", location: "Remote" },
    { id: 19, name: "DigitalCrafts", location: "Remote" },
    { id: 20, name: "Eleven Fifty Academy", location: "Remote" },
    { id: 21, name: "Flatiron School", location: "Remote" },
    { id: 22, name: "Fullstack Academy", location: "Remote" },
    { id: 23, name: "Galvanize", location: "Remote" },
    { id: 24, name: "General Assembly", location: "Remote" },
    { id: 25, name: "Grand Circus", location: "Remote" },
    { id: 26, name: "Hack Reactor", location: "Remote" },
    { id: 27, name: "Ironhack", location: "Remote" },
    { id: 28, name: "Juno College of Technology", location: "Toronto, Canada" },
    { id: 29, name: "Kenzie Academy", location: "Remote" },
    { id: 30, name: "Launch Academy", location: "Remote" },
    { id: 31, name: "LearningFuze", location: "Irvine, CA" },
    { id: 32, name: "MAX Technical Training", location: "Remote" },
    { id: 33, name: "Nashville Software School", location: "Remote" },
    { id: 34, name: "NuCamp", location: "Remote" },
    { id: 35, name: "Parsity", location: "San Francisco, CA" },
    { id: 36, name: "Prime Digital Academy", location: "Minneapolis, Minnesota" },
    { id: 37, name: "Rithm School", location: "San Francisco, CA" },
    { id: 38, name: "SIT Academy – Constructor Learning", location: "Miami, FL" },
    { id: 39, name: "Simplilearn", location: "Remote" },
    { id: 40, name: "Skill Distillery", location: "Remote" },
    { id: 41, name: "Tech Elevator", location: "Cleveland, Ohio" },
    { id: 42, name: "Tech Talent & Strategy", location: "Remote" },
    { id: 43, name: "The Tech Academy", location: "Remote" },
    { id: 44, name: "Thinkful", location: "Remote" },
    { id: 45, name: "TrueCoders", location: "Remote" },
    { id: 46, name: "Turing School of Software and Design", location: "Remote" },
    { id: 47, name: "V School", location: "Remote" },
    { id: 48, name: "Yellow Tail Tech", location: "Los Angeles, CA" },
    { id: 49, name: "Zip Code Wilmington", location: "Wilmington, DE" },
    { id: 50, name: "devCodeCamp", location: "Remote" }
];


const companies = [
    { id: 101, name: "InternetMovies" },
    { id: 102, name: "Orange" },
    { id: 103, name: "Fleshcorp" },
    { id: 104, name: "Acme" },
    { id: 105, name: "Soylent" },
];

const titles = [
    "Software Engineer",
    "Junior Developer",
    "Senior Developer",
    "Frontend Developer",
    "Backend Developer",
    "Full Stack Developer",
];

const salaries = [
    "$50,000 - $60,000",
    "$60,000 - $70,000",
    "$70,000 - $80,000",
    "$80,000 - $90,000",
    "$90,000 - $100,000",
    "$100,000 - $110,000",
    "$110,000 - $120,000",
    "$120,000 - $130,000",
    "$130,000 - $140,000",
    "$140,000 - $150,000",
    "$150,000+"
];

const parseSalary = (salaryRange) => {
    const [min, max] = salaryRange.replace(/\$/g, '').split(' - ').map(s => parseInt(s.replace(/,/g, ''), 10));
    return Math.round((min + max) / 2);
};

const yearsOfExperience = [1, 2, 3, 4, 5];

const locations = ["San Francisco", "New York", "Los Angeles", "Seattle", "Austin"];


const NewOutcome = () => {
    const [bootcampInput, setBootcampInput] = useState('');
    const [companyInput, setCompanyInput] = useState('');
    const [titleInput, setTitleInput] = useState('');
    const [salaryInput, setSalaryInput] = useState('');
    const [yoeInput, setYoeInput] = useState('');
    const [locationInput, setLocationInput] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isFormValid()) {
            setIsFormInvalid(true);
            return;
        }

        setIsFormInvalid(false);
        e.preventDefault();

        const bootcampId = bootcamps.find(bc => bc.name === bootcampInput)?.id;
        const companyId = companies.find(comp => comp.name === companyInput)?.id;
    
        console.log('Title Input:', titleInput);


        try {
            const payload = {
                bootcamp_id: bootcampId,
                company_id: companyId,
                title: titleInput,
                salary: parseSalary(salaryInput),
                yoe: yoeInput,
                location: locationInput,   
            };

            setIsSubmitted(true);
            setTimeout(() => navigate('/'), 2000);

            const response = await fetch('/api/graduates', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
        } catch (error) {
            console.error("Error submitting form: ", error);
        }
    };

    const isFormValid = () => {
        return bootcampInput && companyInput && titleInput && salaryInput && yoeInput && locationInput;
    };

    return (
        <div className="new-outcome-container">
            <h1 className="text-2xl font-bold mb-4">New Outcome</h1>
            <form onSubmit={handleSubmit} className="new-outcome-form form-control w-full max-w-xs">
                {/* bootcamp field */}
                <label className="label">
                    <span className="label-text">Which  boot camp did you attend?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={bootcampInput}
                    onChange={(e) => setBootcampInput(e.target.value)}
                    >
                        <option value="">Select Bootcamp</option>
                        {bootcamps.map(bc => (
                            <option key={bc.id} value={bc.name}>{bc.name}</option>
                        ))}
                    </select>
            

                {/* company field */}
                <label className="label">
                    <span className="label-text">Which company do you work for?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={companyInput}
                    onChange={(e) => setCompanyInput(e.target.value)}
                >
                    <option value="">Select Company</option>
                    {companies.map(company => (
                <option key={company.id} value={company.name}>{company.name}</option>
                    ))}
                </select>

                {/* title field */}
                <label className="label">
                    <span className="label-text">What is your current title/level?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={titleInput}
                    onChange={(e) => setTitleInput(e.target.value)}
                    
                >
                    <option value="">Select Title</option>
                    {titles.map(title => (
                        <option key={title} value={title}>{title}</option>
                    ))}
                </select>

                {/* salary field */}
                <label className="label">
                    <span className="label-text">What is your current salary?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={salaryInput}
                    onChange={(e) => setSalaryInput(e.target.value)}
                >
                    <option value="">Select Salary Range</option>
                    {salaries.map(salary => (
                        <option key={salary} value={salary}>{salary}</option>
                    ))}
                </select>



                {/* years of experience field */}
                <label className="label">
                    <span className="label-text">How many years of experience do you have?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={yoeInput}
                    onChange={(e) => setYoeInput(e.target.value)}
                >
                    <option value="">Select Years of Experience</option>
                    {yearsOfExperience.map(yoe => (
                        <option key={yoe} value={yoe}>{yoe}</option>
                    ))}
                </select>

                {/* location Field  */}
                <label className="label">
                    <span className="label-text">Where are you located?</span>
                </label>
                <select 
                    className="input input-bordered w-full max-w-xs"
                    value={locationInput}
                    onChange={(e) => setLocationInput(e.target.value)}
                >
                    <option value="">Select Location</option>
                    {locations.map(location => (
                        <option key={location} value={location}>{location}</option>
                    ))}
                </select>

                <div className="flex items-center space-x-2">
                    <button id="cancel-btn" className="btn" type="button" onClick={() => navigate('/')}>Cancel</button>
                    <button id="submit-btn" className="btn" type="submit">Submit</button>
                </div>

                 {/* validation message */}
                {isFormInvalid && <p className="text-red-500">Please fill out all form fields.</p>} 

                {/* success message */}
                {isSubmitted && <p className="text-green-500">Success! Going back...</p>}
            </form>
        </div>
        
    );
}

export default NewOutcome;
