import React, { useState, useEffect } from 'react';
import GraduateModal from './GraduateModal';

const Home = () => {
  const [graduates, setGraduates] = useState([]);
  const [bootcampsData, setBootcampsData] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [activeGraduate, setActiveGraduate] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //modal logic
  const openModal = (graduate) => {
    setActiveGraduate(graduate);
    setIsModalOpen(true);
  };

  // fetch grad data
  useEffect(() => {
    const fetchData = async (url, setState) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setState(data);
      } catch (error) {
        console.error(`Error fetching data from ${url}: `, error);
      }
    };

    fetchData('/api/graduates', setGraduates);
    fetchData('/api/bootcamps', setBootcampsData);
    fetchData('/api/companies', setCompaniesData);
  }, []);

  // function to map IDs to names  
  const mapIdToName = (id, type) => {
    let dataSet;

    if (type === 'bootcamp') {
        dataSet = bootcampsData;
    } else if (type === 'company') {
        dataSet = companiesData;
    } else {
        return 'Unknown Type';
    }

    if (!dataSet || dataSet.length === 0) {
        return 'Loading...';
    }

    const item = dataSet.find(data => data.id === id);
    return item ? item.name : 'Not Found';
};

return (
  <div className={`flex flex-col w-screen ${activeGraduate}`}>
   {isModalOpen && activeGraduate && (
    <GraduateModal
      graduate={activeGraduate}
      onClose={() => {
        setActiveGraduate(null);
        setIsModalOpen(false);
      }}
      bootcampName={mapIdToName(activeGraduate.bootcamp_id, 'bootcamp')}
      companyName={mapIdToName(activeGraduate.company_id, 'company')}
    />
  )}
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 overflow-auto gap-4 p-4">
      {graduates.slice(0).reverse().map((graduate) => {
        return (
      <div key={graduate.id} className="card bg-white rounded-lg border shadow-md p-4 w-full mx-auto h-[190px]" onClick={() => openModal(graduate)}>
            <h3 className="card-content font-bold mb-1 text-center">{mapIdToName(graduate.bootcamp_id, 'bootcamp')}</h3>
            <p className="card-content">Company: {mapIdToName(graduate.company_id, 'company')}</p>
            <p className="card-content">Title: {graduate.title}</p>
            <p className="card-content">Salary: ${graduate.salary}</p>
            <p className="card-content">Location: {graduate.location}</p>
          </div>
        );
      })}
    </div>
  </div>
);

}

export default Home;