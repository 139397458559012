import React, { useEffect } from 'react';
import './GraduateModal.css';

const GraduateModal = ({ graduate, onClose, bootcampName, companyName }) => {
  // check if the clicked element is outside the modal
  const handleClickOutside = (event) => {
    const modal = document.querySelector('.modal-box');
    if (modal && !modal.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }); 

  if (!graduate) return null;

  return (
    <div className="modal modal-open">
      <div className="modal-box">
        <h2 className="font-bold text-lg">{graduate.name}</h2>
        <p>Bootcamp: {bootcampName}</p>
        <p>Company: {companyName}</p>
        <p>Title/Level: {graduate.title}</p>
        <p>Salary: ${graduate.salary}</p>
        <p>Years of Experience: {graduate.yoe}</p>
        <p>Location: {graduate.location}</p>
        <div className="modal-action">
          <label htmlFor="my-modal" className="btn" onClick={onClose}>Close</label>
        </div>
      </div>
    </div>
  );
};

export default GraduateModal;
