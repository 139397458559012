import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import Navbar from './components/Navbar';
import Navbar2 from './components/Navbar2';
import Home from './components/Home';
import Newoutcome from './components/Newoutcome';
import Companies from './components/Companies';
import Bootcamps from './components/Bootcamps';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Navbar2 />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/newoutcome" element={<Newoutcome />} />
          <Route path ="/bootcamps" element={<Bootcamps />} />
          <Route path ="/companies" element={<Companies />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
